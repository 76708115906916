import styled from "styled-components";

export * from "./Background";

export const PageContainer = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  padding: 3rem;
  @media only screen and (max-width: 960px) {
    padding: 2rem;
  }
`;

export const PageHeader = styled.header``;

export const PageMain = styled.main`
  padding: 1rem;
`;
