export const STREETS_MAP: {
  [key: string]: {
    id: string;
    name: string;
    addresses: string[];
  };
} = {
  "ape-lane": {
    id: "ape-lane",
    name: "Ape Lane",
    addresses: [
      "27-ape-lane",
      "28-ape-lane",
      "29-ape-lane",
      "30-ape-lane",
      "31-ape-lane",
      "35-ape-lane",
      "36-ape-lane",
      "37-ape-lane",
      "38-ape-lane",
      "39-ape-lane",
      "40-ape-lane",
      "41-ape-lane",
      "42-ape-lane",
      "43-ape-lane",
      "44-ape-lane",
      "45-ape-lane",
      "46-ape-lane",
      "48-ape-lane",
      "52-ape-lane",
      "53-ape-lane",
      "54-ape-lane",
      "55-ape-lane",
      "56-ape-lane",
      "57-ape-lane",
      "58-ape-lane",
      "59-ape-lane",
      "60-ape-lane",
      "61-ape-lane",
      "63-ape-lane",
      "65-ape-lane",
      "66-ape-lane",
      "67-ape-lane",
      "68-ape-lane",
      "69-ape-lane",
      "70-ape-lane",
      "71-ape-lane",
      "72-ape-lane",
      "73-ape-lane",
      "75-ape-lane",
      "76-ape-lane",
      "77-ape-lane",
      "78-ape-lane",
      "79-ape-lane",
      "81-ape-lane",
      "82-ape-lane",
      "83-ape-lane",
      "84-ape-lane",
      "85-ape-lane",
      "87-ape-lane",
      "89-ape-lane",
      "92-ape-lane",
      "93-ape-lane",
      "94-ape-lane",
      "95-ape-lane",
      "96-ape-lane",
      "98-ape-lane",
      "99-ape-lane",
      "100-ape-lane",
      "101-ape-lane",
      "102-ape-lane",
      "106-ape-lane",
      "107-ape-lane",
      "108-ape-lane",
      "109-ape-lane",
      "110-ape-lane",
      "111-ape-lane",
      "112-ape-lane",
      "114-ape-lane",
      "115-ape-lane",
      "116-ape-lane",
      "118-ape-lane",
      "119-ape-lane",
      "120-ape-lane",
      "122-ape-lane",
    ],
  },
  "rosa-street": {
    id: "rosa-street",
    name: "Rosa Street",
    addresses: [
      "30-rosa-street",
      "31-rosa-street",
      "33-rosa-street",
      "34-rosa-street",
      "35-rosa-street",
      "37-rosa-street",
      "38-rosa-street",
      "39-rosa-street",
      "40-rosa-street",
      "41-rosa-street",
      "42-rosa-street",
      "43-rosa-street",
      "44-rosa-street",
      "45-rosa-street",
      "46-rosa-street",
      "47-rosa-street",
      "49-rosa-street",
      "50-rosa-street",
      "53-rosa-street",
      "54-rosa-street",
      "55-rosa-street",
      "56-rosa-street",
      "57-rosa-street",
      "59-rosa-street",
      "60-rosa-street",
      "62-rosa-street",
      "63-rosa-street",
      "64-rosa-street",
      "65-rosa-street",
      "67-rosa-street",
      "68-rosa-street",
      "70-rosa-street",
      "75-rosa-street",
      "76-rosa-street",
      "77-rosa-street",
      "78-rosa-street",
      "79-rosa-street",
      "80-rosa-street",
      "81-rosa-street",
      "83-rosa-street",
      "84-rosa-street",
      "85-rosa-street",
      "86-rosa-street",
      "87-rosa-street",
      "89-rosa-street",
      "90-rosa-street",
      "91-rosa-street",
      "92-rosa-street",
      "93-rosa-street",
      "94-rosa-street",
      "95-rosa-street",
      "96-rosa-street",
      "97-rosa-street",
      "98-rosa-street",
      "100-rosa-street",
      "101-rosa-street",
      "102-rosa-street",
      "103-rosa-street",
      "104-rosa-street",
      "105-rosa-street",
      "106-rosa-street",
      "107-rosa-street",
      "108-rosa-street",
      "109-rosa-street",
    ],
  },
  "stewart-avenue": {
    id: "stewart-avenue",
    name: "Stewart Avenue",
    addresses: [
      "27-stewart-avenue",
      "28-stewart-avenue",
      "30-stewart-avenue",
      "31-stewart-avenue",
      "35-stewart-avenue",
      "36-stewart-avenue",
      "38-stewart-avenue",
      "42-stewart-avenue",
      "43-stewart-avenue",
      "46-stewart-avenue",
      "47-stewart-avenue",
      "48-stewart-avenue",
      "50-stewart-avenue",
      "51-stewart-avenue",
      "52-stewart-avenue",
      "53-stewart-avenue",
      "54-stewart-avenue",
      "56-stewart-avenue",
      "58-stewart-avenue",
      "61-stewart-avenue",
      "62-stewart-avenue",
      "63-stewart-avenue",
      "64-stewart-avenue",
      "66-stewart-avenue",
      "71-stewart-avenue",
      "72-stewart-avenue",
      "73-stewart-avenue",
      "75-stewart-avenue",
      "77-stewart-avenue",
      "78-stewart-avenue",
      "84-stewart-avenue",
      "85-stewart-avenue",
      "86-stewart-avenue",
      "88-stewart-avenue",
      "90-stewart-avenue",
      "91-stewart-avenue",
      "94-stewart-avenue",
      "96-stewart-avenue",
      "99-stewart-avenue",
      "100-stewart-avenue",
      "101-stewart-avenue",
      "102-stewart-avenue",
      "103-stewart-avenue",
      "104-stewart-avenue",
      "107-stewart-avenue",
      "108-stewart-avenue",
      "109-stewart-avenue",
      "113-stewart-avenue",
      "114-stewart-avenue",
      "115-stewart-avenue",
      "117-stewart-avenue",
      "118-stewart-avenue",
      "120-stewart-avenue",
      "124-stewart-avenue",
      "125-stewart-avenue",
      "126-stewart-avenue",
      "127-stewart-avenue",
      "128-stewart-avenue",
      "131-stewart-avenue",
      "132-stewart-avenue",
      "133-stewart-avenue",
      "134-stewart-avenue",
      "136-stewart-avenue",
      "137-stewart-avenue",
      "138-stewart-avenue",
      "139-stewart-avenue",
      "142-stewart-avenue",
      "143-stewart-avenue",
      "144-stewart-avenue",
      "147-stewart-avenue",
      "149-stewart-avenue",
      "150-stewart-avenue",
      "152-stewart-avenue",
      "153-stewart-avenue",
      "156-stewart-avenue",
      "157-stewart-avenue",
      "158-stewart-avenue",
      "159-stewart-avenue",
      "160-stewart-avenue",
      "161-stewart-avenue",
      "162-stewart-avenue",
      "163-stewart-avenue",
      "164-stewart-avenue",
      "166-stewart-avenue",
      "168-stewart-avenue",
      "171-stewart-avenue",
      "172-stewart-avenue",
    ],
  },
  "supple-circle": {
    id: "supple-circle",
    name: "Supple Circle",
    addresses: [
      "30-supple-circle",
      "31-supple-circle",
      "32-supple-circle",
      "33-supple-circle",
      "34-supple-circle",
      "35-supple-circle",
      "36-supple-circle",
      "37-supple-circle",
      "38-supple-circle",
      "39-supple-circle",
      "40-supple-circle",
      "41-supple-circle",
      "42-supple-circle",
      "43-supple-circle",
      "44-supple-circle",
      "45-supple-circle",
      "46-supple-circle",
      "47-supple-circle",
      "48-supple-circle",
      "49-supple-circle",
      "50-supple-circle",
      "51-supple-circle",
      "52-supple-circle",
      "53-supple-circle",
      "55-supple-circle",
      "56-supple-circle",
      "57-supple-circle",
      "58-supple-circle",
      "59-supple-circle",
      "60-supple-circle",
      "61-supple-circle",
      "62-supple-circle",
      "63-supple-circle",
      "64-supple-circle",
      "65-supple-circle",
      "67-supple-circle",
      "68-supple-circle",
      "69-supple-circle",
      "70-supple-circle",
      "71-supple-circle",
      "72-supple-circle",
      "73-supple-circle",
      "74-supple-circle",
      "75-supple-circle",
      "76-supple-circle",
      "77-supple-circle",
      "78-supple-circle",
      "79-supple-circle",
      "80-supple-circle",
      "81-supple-circle",
      "82-supple-circle",
      "83-supple-circle",
      "84-supple-circle",
      "86-supple-circle",
      "87-supple-circle",
      "89-supple-circle",
      "91-supple-circle",
      "92-supple-circle",
      "93-supple-circle",
      "94-supple-circle",
      "95-supple-circle",
      "96-supple-circle",
      "97-supple-circle",
      "98-supple-circle",
      "99-supple-circle",
      "100-supple-circle",
      "101-supple-circle",
      "102-supple-circle",
      "103-supple-circle",
      "104-supple-circle",
      "106-supple-circle",
      "107-supple-circle",
      "108-supple-circle",
      "109-supple-circle",
      "110-supple-circle",
      "111-supple-circle",
      "112-supple-circle",
      "114-supple-circle",
      "115-supple-circle",
      "116-supple-circle",
      "117-supple-circle",
      "118-supple-circle",
      "119-supple-circle",
      "120-supple-circle",
    ],
  },
  "teeps-lane": {
    id: "teeps-lane",
    name: "Teeps Lane",
    addresses: [
      "8-teeps-lane",
      "14-teeps-lane",
      "15-teeps-lane",
      "16-teeps-lane",
      "18-teeps-lane",
      "20-teeps-lane",
      "23-teeps-lane",
      "24-teeps-lane",
      "25-teeps-lane",
      "26-teeps-lane",
      "27-teeps-lane",
      "28-teeps-lane",
      "29-teeps-lane",
      "31-teeps-lane",
      "32-teeps-lane",
      "33-teeps-lane",
      "34-teeps-lane",
      "36-teeps-lane",
      "37-teeps-lane",
      "38-teeps-lane",
      "39-teeps-lane",
      "40-teeps-lane",
      "41-teeps-lane",
      "42-teeps-lane",
      "43-teeps-lane",
      "44-teeps-lane",
      "46-teeps-lane",
      "47-teeps-lane",
      "49-teeps-lane",
      "55-teeps-lane",
      "56-teeps-lane",
      "59-teeps-lane",
      "61-teeps-lane",
      "62-teeps-lane",
      "63-teeps-lane",
      "66-teeps-lane",
      "68-teeps-lane",
      "69-teeps-lane",
      "70-teeps-lane",
      "72-teeps-lane",
      "73-teeps-lane",
      "78-teeps-lane",
      "81-teeps-lane",
      "83-teeps-lane",
      "84-teeps-lane",
      "87-teeps-lane",
      "88-teeps-lane",
      "89-teeps-lane",
      "90-teeps-lane",
      "95-teeps-lane",
      "97-teeps-lane",
      "98-teeps-lane",
      "100-teeps-lane",
      "101-teeps-lane",
      "102-teeps-lane",
      "104-teeps-lane",
      "107-teeps-lane",
      "108-teeps-lane",
      "109-teeps-lane",
      "110-teeps-lane",
    ],
  },
  "wall-street": {
    id: "wall-street",
    name: "Wall Street",
    addresses: [
      "60-wall-street",
      "62-wall-street",
      "63-wall-street",
      "64-wall-street",
      "65-wall-street",
      "67-wall-street",
      "68-wall-street",
      "70-wall-street",
      "71-wall-street",
      "72-wall-street",
      "73-wall-street",
      "74-wall-street",
      "75-wall-street",
      "77-wall-street",
      "78-wall-street",
      "79-wall-street",
      "80-wall-street",
      "81-wall-street",
      "82-wall-street",
      "83-wall-street",
      "86-wall-street",
      "87-wall-street",
      "88-wall-street",
      "89-wall-street",
      "90-wall-street",
      "91-wall-street",
      "95-wall-street",
      "96-wall-street",
      "97-wall-street",
      "99-wall-street",
      "100-wall-street",
      "101-wall-street",
      "107-wall-street",
      "108-wall-street",
      "109-wall-street",
      "110-wall-street",
      "111-wall-street",
      "112-wall-street",
      "113-wall-street",
      "115-wall-street",
      "116-wall-street",
      "119-wall-street",
      "120-wall-street",
      "122-wall-street",
      "123-wall-street",
      "126-wall-street",
      "128-wall-street",
      "130-wall-street",
      "131-wall-street",
      "133-wall-street",
      "134-wall-street",
    ],
  },
  "wisteria-lane": {
    id: "wisteria-lane",
    name: "Wisteria Lane",
    addresses: [
      "111-wisteria-lane",
      "114-wisteria-lane",
      "116-wisteria-lane",
      "119-wisteria-lane",
      "120-wisteria-lane",
      "123-wisteria-lane",
      "130-wisteria-lane",
      "131-wisteria-lane",
      "137-wisteria-lane",
      "139-wisteria-lane",
      "143-wisteria-lane",
      "146-wisteria-lane",
      "149-wisteria-lane",
      "150-wisteria-lane",
      "154-wisteria-lane",
      "157-wisteria-lane",
      "159-wisteria-lane",
      "161-wisteria-lane",
      "163-wisteria-lane",
      "166-wisteria-lane",
      "168-wisteria-lane",
      "170-wisteria-lane",
      "173-wisteria-lane",
      "174-wisteria-lane",
      "179-wisteria-lane",
      "181-wisteria-lane",
      "184-wisteria-lane",
      "190-wisteria-lane",
      "191-wisteria-lane",
      "193-wisteria-lane",
      "194-wisteria-lane",
      "196-wisteria-lane",
      "197-wisteria-lane",
    ],
  },
  "canal-street": {
    id: "canal-street",
    name: "Canal Street",
    addresses: [
      "119-canal-street",
      "120-canal-street",
      "122-canal-street",
      "123-canal-street",
      "124-canal-street",
      "125-canal-street",
      "126-canal-street",
      "127-canal-street",
      "128-canal-street",
      "129-canal-street",
      "136-canal-street",
      "137-canal-street",
      "138-canal-street",
      "139-canal-street",
      "142-canal-street",
      "143-canal-street",
      "145-canal-street",
      "146-canal-street",
      "148-canal-street",
      "150-canal-street",
      "151-canal-street",
      "153-canal-street",
      "154-canal-street",
      "156-canal-street",
      "162-canal-street",
      "164-canal-street",
      "166-canal-street",
      "167-canal-street",
      "168-canal-street",
      "169-canal-street",
      "170-canal-street",
      "172-canal-street",
      "176-canal-street",
      "178-canal-street",
      "181-canal-street",
      "182-canal-street",
      "183-canal-street",
      "184-canal-street",
      "185-canal-street",
      "186-canal-street",
      "187-canal-street",
      "188-canal-street",
      "189-canal-street",
      "193-canal-street",
      "194-canal-street",
      "195-canal-street",
      "196-canal-street",
      "197-canal-street",
      "198-canal-street",
      "200-canal-street",
      "201-canal-street",
      "202-canal-street",
      "203-canal-street",
      "204-canal-street",
      "205-canal-street",
      "206-canal-street",
      "207-canal-street",
      "208-canal-street",
      "209-canal-street",
      "210-canal-street",
    ],
  },
  "arcadian-alley": {
    id: "arcadian-alley",
    name: "Arcadian Alley",
    addresses: [
      "12-arcadian-alley",
      "14-arcadian-alley",
      "25-arcadian-alley",
      "27-arcadian-alley",
    ],
  },
  "jump-street": {
    id: "jump-street",
    name: "Jump Street",
    addresses: [
      "15-jump-street",
      "16-jump-street",
      "17-jump-street",
      "18-jump-street",
      "19-jump-street",
      "20-jump-street",
      "21-jump-street",
      "22-jump-street",
      "23-jump-street",
      "25-jump-street",
      "26-jump-street",
      "27-jump-street",
      "28-jump-street",
      "29-jump-street",
      "30-jump-street",
    ],
  },
  "brain-lane": {
    id: "brain-lane",
    name: "Brain Lane",
    addresses: ["16-brain-lane"],
  },
  "baker-street": {
    id: "baker-street",
    name: "Baker Street",
    addresses: [
      "190-baker-street",
      "192-baker-street",
      "193-baker-street",
      "194-baker-street",
      "195-baker-street",
      "196-baker-street",
      "197-baker-street",
      "198-baker-street",
      "200-baker-street",
      "201-baker-street",
      "203-baker-street",
      "204-baker-street",
      "205-baker-street",
      "207-baker-street",
      "208-baker-street",
      "209-baker-street",
      "210-baker-street",
      "211-baker-street",
      "212-baker-street",
      "214-baker-street",
      "215-baker-street",
      "216-baker-street",
      "217-baker-street",
      "218-baker-street",
      "222-baker-street",
      "225-baker-street",
      "226-baker-street",
      "227-baker-street",
      "228-baker-street",
      "229-baker-street",
      "230-baker-street",
      "231-baker-street",
      "232-baker-street",
      "234-baker-street",
      "235-baker-street",
      "237-baker-street",
      "238-baker-street",
      "239-baker-street",
      "240-baker-street",
      "241-baker-street",
      "242-baker-street",
      "245-baker-street",
      "246-baker-street",
      "247-baker-street",
      "248-baker-street",
      "249-baker-street",
      "250-baker-street",
      "251-baker-street",
      "252-baker-street",
      "253-baker-street",
      "254-baker-street",
      "256-baker-street",
      "258-baker-street",
      "261-baker-street",
      "262-baker-street",
      "263-baker-street",
      "264-baker-street",
      "267-baker-street",
      "268-baker-street",
      "269-baker-street",
      "271-baker-street",
      "272-baker-street",
      "276-baker-street",
    ],
  },
  "bit-boulevard": {
    id: "bit-boulevard",
    name: "Bit Boulevard",
    addresses: [
      "201-bit-boulevard",
      "202-bit-boulevard",
      "203-bit-boulevard",
      "204-bit-boulevard",
      "206-bit-boulevard",
      "207-bit-boulevard",
      "208-bit-boulevard",
      "210-bit-boulevard",
      "212-bit-boulevard",
      "213-bit-boulevard",
      "214-bit-boulevard",
      "215-bit-boulevard",
      "216-bit-boulevard",
      "217-bit-boulevard",
      "218-bit-boulevard",
      "219-bit-boulevard",
      "221-bit-boulevard",
      "222-bit-boulevard",
      "223-bit-boulevard",
      "224-bit-boulevard",
      "225-bit-boulevard",
      "226-bit-boulevard",
      "227-bit-boulevard",
      "228-bit-boulevard",
      "229-bit-boulevard",
      "230-bit-boulevard",
      "232-bit-boulevard",
      "233-bit-boulevard",
      "234-bit-boulevard",
      "235-bit-boulevard",
      "237-bit-boulevard",
      "238-bit-boulevard",
      "239-bit-boulevard",
      "240-bit-boulevard",
      "241-bit-boulevard",
      "242-bit-boulevard",
      "243-bit-boulevard",
      "244-bit-boulevard",
      "245-bit-boulevard",
      "246-bit-boulevard",
      "247-bit-boulevard",
      "248-bit-boulevard",
      "249-bit-boulevard",
      "250-bit-boulevard",
      "251-bit-boulevard",
      "252-bit-boulevard",
      "253-bit-boulevard",
      "254-bit-boulevard",
      "255-bit-boulevard",
      "256-bit-boulevard",
      "257-bit-boulevard",
      "258-bit-boulevard",
      "261-bit-boulevard",
      "262-bit-boulevard",
      "263-bit-boulevard",
      "264-bit-boulevard",
      "266-bit-boulevard",
      "267-bit-boulevard",
      "268-bit-boulevard",
      "269-bit-boulevard",
      "270-bit-boulevard",
      "271-bit-boulevard",
      "273-bit-boulevard",
      "274-bit-boulevard",
      "275-bit-boulevard",
      "276-bit-boulevard",
      "277-bit-boulevard",
      "278-bit-boulevard",
      "279-bit-boulevard",
      "280-bit-boulevard",
      "281-bit-boulevard",
      "282-bit-boulevard",
      "283-bit-boulevard",
      "284-bit-boulevard",
      "285-bit-boulevard",
      "286-bit-boulevard",
      "287-bit-boulevard",
      "288-bit-boulevard",
      "289-bit-boulevard",
      "290-bit-boulevard",
      "291-bit-boulevard",
      "292-bit-boulevard",
      "293-bit-boulevard",
      "294-bit-boulevard",
      "295-bit-boulevard",
      "296-bit-boulevard",
      "297-bit-boulevard",
      "298-bit-boulevard",
      "299-bit-boulevard",
      "300-bit-boulevard",
      "301-bit-boulevard",
      "302-bit-boulevard",
      "303-bit-boulevard",
      "304-bit-boulevard",
      "305-bit-boulevard",
      "307-bit-boulevard",
      "308-bit-boulevard",
      "310-bit-boulevard",
      "312-bit-boulevard",
      "313-bit-boulevard",
      "314-bit-boulevard",
      "315-bit-boulevard",
      "316-bit-boulevard",
      "317-bit-boulevard",
      "318-bit-boulevard",
      "319-bit-boulevard",
      "320-bit-boulevard",
      "323-bit-boulevard",
      "327-bit-boulevard",
      "329-bit-boulevard",
    ],
  },
  "delray-circle": {
    id: "delray-circle",
    name: "Delray Circle",
    addresses: [
      "207-delray-circle",
      "208-delray-circle",
      "209-delray-circle",
      "210-delray-circle",
      "212-delray-circle",
      "213-delray-circle",
      "214-delray-circle",
      "215-delray-circle",
      "216-delray-circle",
      "218-delray-circle",
      "219-delray-circle",
      "220-delray-circle",
      "223-delray-circle",
      "226-delray-circle",
      "227-delray-circle",
      "229-delray-circle",
      "232-delray-circle",
      "233-delray-circle",
      "235-delray-circle",
      "238-delray-circle",
      "241-delray-circle",
      "242-delray-circle",
      "243-delray-circle",
      "245-delray-circle",
      "246-delray-circle",
      "249-delray-circle",
      "250-delray-circle",
      "251-delray-circle",
      "253-delray-circle",
      "256-delray-circle",
      "259-delray-circle",
      "263-delray-circle",
      "265-delray-circle",
      "267-delray-circle",
      "268-delray-circle",
      "269-delray-circle",
      "270-delray-circle",
      "271-delray-circle",
      "274-delray-circle",
      "275-delray-circle",
      "277-delray-circle",
      "278-delray-circle",
      "282-delray-circle",
      "284-delray-circle",
      "285-delray-circle",
      "286-delray-circle",
      "287-delray-circle",
      "289-delray-circle",
      "295-delray-circle",
      "296-delray-circle",
      "297-delray-circle",
      "298-delray-circle",
      "299-delray-circle",
      "300-delray-circle",
      "302-delray-circle",
      "303-delray-circle",
      "304-delray-circle",
      "305-delray-circle",
      "308-delray-circle",
      "309-delray-circle",
      "310-delray-circle",
      "312-delray-circle",
      "313-delray-circle",
      "314-delray-circle",
      "315-delray-circle",
      "317-delray-circle",
      "318-delray-circle",
      "320-delray-circle",
      "321-delray-circle",
      "323-delray-circle",
      "324-delray-circle",
      "329-delray-circle",
      "331-delray-circle",
      "333-delray-circle",
      "334-delray-circle",
      "335-delray-circle",
      "338-delray-circle",
      "339-delray-circle",
      "340-delray-circle",
      "341-delray-circle",
      "343-delray-circle",
      "344-delray-circle",
      "345-delray-circle",
      "346-delray-circle",
      "347-delray-circle",
      "348-delray-circle",
      "350-delray-circle",
      "351-delray-circle",
      "353-delray-circle",
      "354-delray-circle",
      "357-delray-circle",
      "359-delray-circle",
    ],
  },
  "privet-drive": {
    id: "privet-drive",
    name: "Privet Drive",
    addresses: [
      "232-privet-drive",
      "233-privet-drive",
      "235-privet-drive",
      "236-privet-drive",
      "237-privet-drive",
      "239-privet-drive",
      "240-privet-drive",
      "241-privet-drive",
      "243-privet-drive",
      "244-privet-drive",
      "245-privet-drive",
      "247-privet-drive",
      "248-privet-drive",
      "250-privet-drive",
      "251-privet-drive",
      "252-privet-drive",
      "256-privet-drive",
      "257-privet-drive",
      "260-privet-drive",
      "261-privet-drive",
      "263-privet-drive",
      "264-privet-drive",
      "265-privet-drive",
      "266-privet-drive",
      "267-privet-drive",
    ],
  },
  "pico-street": {
    id: "pico-street",
    name: "Pico Street",
    addresses: [
      "247-pico-street",
      "248-pico-street",
      "249-pico-street",
      "252-pico-street",
      "253-pico-street",
      "259-pico-street",
      "260-pico-street",
      "261-pico-street",
      "262-pico-street",
      "264-pico-street",
      "267-pico-street",
      "268-pico-street",
      "270-pico-street",
      "274-pico-street",
      "275-pico-street",
      "276-pico-street",
      "277-pico-street",
      "278-pico-street",
      "281-pico-street",
      "282-pico-street",
      "283-pico-street",
      "284-pico-street",
      "285-pico-street",
      "286-pico-street",
      "289-pico-street",
      "292-pico-street",
      "293-pico-street",
      "294-pico-street",
      "296-pico-street",
      "298-pico-street",
      "299-pico-street",
      "300-pico-street",
      "302-pico-street",
      "303-pico-street",
      "305-pico-street",
      "306-pico-street",
      "309-pico-street",
      "310-pico-street",
      "311-pico-street",
      "313-pico-street",
      "314-pico-street",
      "315-pico-street",
      "316-pico-street",
      "318-pico-street",
      "321-pico-street",
      "323-pico-street",
      "324-pico-street",
      "326-pico-street",
      "327-pico-street",
      "328-pico-street",
      "330-pico-street",
      "332-pico-street",
      "338-pico-street",
      "339-pico-street",
      "340-pico-street",
      "342-pico-street",
      "343-pico-street",
      "344-pico-street",
      "346-pico-street",
      "348-pico-street",
      "350-pico-street",
      "354-pico-street",
      "355-pico-street",
      "356-pico-street",
      "357-pico-street",
      "358-pico-street",
      "363-pico-street",
      "364-pico-street",
      "367-pico-street",
      "369-pico-street",
      "370-pico-street",
      "371-pico-street",
      "373-pico-street",
      "374-pico-street",
      "377-pico-street",
      "378-pico-street",
      "380-pico-street",
      "381-pico-street",
      "382-pico-street",
      "383-pico-street",
      "384-pico-street",
      "388-pico-street",
      "389-pico-street",
      "390-pico-street",
      "392-pico-street",
      "397-pico-street",
      "399-pico-street",
      "401-pico-street",
    ],
  },
  "frontier-street": {
    id: "frontier-street",
    name: "Frontier Street",
    addresses: [
      "261-frontier-street",
      "263-frontier-street",
      "269-frontier-street",
      "274-frontier-street",
      "275-frontier-street",
      "276-frontier-street",
    ],
  },
  "first-avenue": {
    id: "first-avenue",
    name: "First Avenue",
    addresses: [
      "307-first-avenue",
      "308-first-avenue",
      "309-first-avenue",
      "311-first-avenue",
      "312-first-avenue",
      "313-first-avenue",
      "314-first-avenue",
      "316-first-avenue",
      "317-first-avenue",
      "318-first-avenue",
      "320-first-avenue",
      "321-first-avenue",
      "322-first-avenue",
      "323-first-avenue",
      "324-first-avenue",
      "325-first-avenue",
      "326-first-avenue",
      "328-first-avenue",
      "330-first-avenue",
      "335-first-avenue",
      "336-first-avenue",
      "337-first-avenue",
      "338-first-avenue",
      "340-first-avenue",
      "341-first-avenue",
      "343-first-avenue",
      "345-first-avenue",
      "348-first-avenue",
      "349-first-avenue",
      "350-first-avenue",
      "352-first-avenue",
      "353-first-avenue",
      "355-first-avenue",
      "358-first-avenue",
      "360-first-avenue",
      "361-first-avenue",
      "362-first-avenue",
      "364-first-avenue",
      "365-first-avenue",
      "368-first-avenue",
      "369-first-avenue",
      "372-first-avenue",
      "373-first-avenue",
      "374-first-avenue",
      "375-first-avenue",
      "376-first-avenue",
      "378-first-avenue",
      "379-first-avenue",
      "381-first-avenue",
      "382-first-avenue",
      "384-first-avenue",
      "385-first-avenue",
      "387-first-avenue",
      "388-first-avenue",
      "390-first-avenue",
      "391-first-avenue",
      "392-first-avenue",
      "393-first-avenue",
      "394-first-avenue",
      "395-first-avenue",
      "397-first-avenue",
      "398-first-avenue",
      "399-first-avenue",
      "400-first-avenue",
      "401-first-avenue",
      "402-first-avenue",
      "403-first-avenue",
      "404-first-avenue",
      "405-first-avenue",
      "409-first-avenue",
      "411-first-avenue",
      "412-first-avenue",
      "413-first-avenue",
      "416-first-avenue",
      "417-first-avenue",
      "418-first-avenue",
      "420-first-avenue",
      "421-first-avenue",
      "423-first-avenue",
      "424-first-avenue",
      "426-first-avenue",
      "428-first-avenue",
      "429-first-avenue",
      "431-first-avenue",
      "432-first-avenue",
      "433-first-avenue",
      "435-first-avenue",
      "438-first-avenue",
      "439-first-avenue",
      "440-first-avenue",
      "443-first-avenue",
      "444-first-avenue",
      "446-first-avenue",
      "449-first-avenue",
      "450-first-avenue",
      "451-first-avenue",
      "452-first-avenue",
      "453-first-avenue",
      "455-first-avenue",
      "456-first-avenue",
    ],
  },
  "sierra-avenue": {
    id: "sierra-avenue",
    name: "Sierra Avenue",
    addresses: [
      "310-sierra-avenue",
      "311-sierra-avenue",
      "312-sierra-avenue",
      "313-sierra-avenue",
      "314-sierra-avenue",
      "315-sierra-avenue",
      "316-sierra-avenue",
      "318-sierra-avenue",
      "319-sierra-avenue",
      "320-sierra-avenue",
      "321-sierra-avenue",
      "322-sierra-avenue",
      "323-sierra-avenue",
      "325-sierra-avenue",
      "326-sierra-avenue",
      "327-sierra-avenue",
      "328-sierra-avenue",
      "329-sierra-avenue",
      "331-sierra-avenue",
      "333-sierra-avenue",
      "336-sierra-avenue",
      "337-sierra-avenue",
      "338-sierra-avenue",
      "339-sierra-avenue",
      "340-sierra-avenue",
      "341-sierra-avenue",
      "342-sierra-avenue",
      "345-sierra-avenue",
      "346-sierra-avenue",
      "350-sierra-avenue",
      "351-sierra-avenue",
      "352-sierra-avenue",
      "357-sierra-avenue",
      "358-sierra-avenue",
      "360-sierra-avenue",
      "361-sierra-avenue",
      "363-sierra-avenue",
      "366-sierra-avenue",
      "371-sierra-avenue",
      "372-sierra-avenue",
      "373-sierra-avenue",
      "374-sierra-avenue",
      "377-sierra-avenue",
      "379-sierra-avenue",
      "384-sierra-avenue",
      "385-sierra-avenue",
      "387-sierra-avenue",
      "388-sierra-avenue",
      "391-sierra-avenue",
      "393-sierra-avenue",
      "396-sierra-avenue",
      "397-sierra-avenue",
      "399-sierra-avenue",
      "400-sierra-avenue",
      "401-sierra-avenue",
      "403-sierra-avenue",
      "404-sierra-avenue",
      "405-sierra-avenue",
      "406-sierra-avenue",
      "407-sierra-avenue",
      "410-sierra-avenue",
      "413-sierra-avenue",
      "417-sierra-avenue",
      "418-sierra-avenue",
      "419-sierra-avenue",
      "421-sierra-avenue",
      "422-sierra-avenue",
      "424-sierra-avenue",
      "425-sierra-avenue",
      "427-sierra-avenue",
      "428-sierra-avenue",
      "432-sierra-avenue",
      "434-sierra-avenue",
      "435-sierra-avenue",
      "438-sierra-avenue",
      "440-sierra-avenue",
      "442-sierra-avenue",
      "443-sierra-avenue",
      "444-sierra-avenue",
      "445-sierra-avenue",
      "446-sierra-avenue",
    ],
  },
  "grime-lane": {
    id: "grime-lane",
    name: "Grime Lane",
    addresses: [
      "320-grime-lane",
      "321-grime-lane",
      "323-grime-lane",
      "325-grime-lane",
      "326-grime-lane",
      "327-grime-lane",
      "328-grime-lane",
      "330-grime-lane",
      "331-grime-lane",
      "337-grime-lane",
      "339-grime-lane",
      "340-grime-lane",
      "344-grime-lane",
      "345-grime-lane",
      "346-grime-lane",
      "349-grime-lane",
      "350-grime-lane",
      "351-grime-lane",
      "353-grime-lane",
      "354-grime-lane",
      "356-grime-lane",
      "357-grime-lane",
      "360-grime-lane",
      "361-grime-lane",
      "362-grime-lane",
      "363-grime-lane",
      "364-grime-lane",
      "365-grime-lane",
      "367-grime-lane",
      "368-grime-lane",
      "371-grime-lane",
      "372-grime-lane",
      "373-grime-lane",
      "374-grime-lane",
      "375-grime-lane",
      "376-grime-lane",
      "378-grime-lane",
      "379-grime-lane",
      "380-grime-lane",
      "381-grime-lane",
      "382-grime-lane",
      "383-grime-lane",
      "384-grime-lane",
      "385-grime-lane",
      "387-grime-lane",
      "389-grime-lane",
      "390-grime-lane",
      "391-grime-lane",
      "396-grime-lane",
      "398-grime-lane",
      "400-grime-lane",
      "401-grime-lane",
      "403-grime-lane",
      "404-grime-lane",
      "405-grime-lane",
      "409-grime-lane",
      "412-grime-lane",
      "414-grime-lane",
      "416-grime-lane",
      "417-grime-lane",
      "420-grime-lane",
      "422-grime-lane",
      "423-grime-lane",
      "424-grime-lane",
      "425-grime-lane",
      "426-grime-lane",
      "429-grime-lane",
      "430-grime-lane",
      "431-grime-lane",
      "432-grime-lane",
      "433-grime-lane",
      "434-grime-lane",
      "440-grime-lane",
      "441-grime-lane",
      "442-grime-lane",
      "443-grime-lane",
      "444-grime-lane",
      "445-grime-lane",
      "448-grime-lane",
      "450-grime-lane",
      "451-grime-lane",
      "452-grime-lane",
      "454-grime-lane",
      "456-grime-lane",
      "457-grime-lane",
      "458-grime-lane",
      "459-grime-lane",
      "460-grime-lane",
      "461-grime-lane",
      "462-grime-lane",
      "465-grime-lane",
      "466-grime-lane",
      "468-grime-lane",
      "469-grime-lane",
    ],
  },
  "lynwood-avenue": {
    id: "lynwood-avenue",
    name: "Lynwood Avenue",
    addresses: [
      "320-lynwood-avenue",
      "323-lynwood-avenue",
      "325-lynwood-avenue",
      "326-lynwood-avenue",
      "327-lynwood-avenue",
      "329-lynwood-avenue",
      "331-lynwood-avenue",
      "332-lynwood-avenue",
      "333-lynwood-avenue",
      "334-lynwood-avenue",
      "335-lynwood-avenue",
      "336-lynwood-avenue",
      "337-lynwood-avenue",
      "338-lynwood-avenue",
      "340-lynwood-avenue",
      "341-lynwood-avenue",
      "342-lynwood-avenue",
      "344-lynwood-avenue",
      "345-lynwood-avenue",
      "347-lynwood-avenue",
      "348-lynwood-avenue",
      "350-lynwood-avenue",
      "351-lynwood-avenue",
      "354-lynwood-avenue",
      "355-lynwood-avenue",
      "356-lynwood-avenue",
      "359-lynwood-avenue",
      "361-lynwood-avenue",
      "362-lynwood-avenue",
      "363-lynwood-avenue",
      "364-lynwood-avenue",
      "365-lynwood-avenue",
      "366-lynwood-avenue",
      "367-lynwood-avenue",
      "368-lynwood-avenue",
      "370-lynwood-avenue",
      "372-lynwood-avenue",
      "373-lynwood-avenue",
      "374-lynwood-avenue",
      "375-lynwood-avenue",
      "376-lynwood-avenue",
      "377-lynwood-avenue",
      "378-lynwood-avenue",
      "379-lynwood-avenue",
      "380-lynwood-avenue",
      "381-lynwood-avenue",
      "382-lynwood-avenue",
      "383-lynwood-avenue",
      "384-lynwood-avenue",
      "386-lynwood-avenue",
      "387-lynwood-avenue",
      "388-lynwood-avenue",
      "389-lynwood-avenue",
      "392-lynwood-avenue",
      "393-lynwood-avenue",
      "394-lynwood-avenue",
      "395-lynwood-avenue",
      "396-lynwood-avenue",
      "399-lynwood-avenue",
      "401-lynwood-avenue",
      "402-lynwood-avenue",
      "403-lynwood-avenue",
      "404-lynwood-avenue",
      "406-lynwood-avenue",
      "407-lynwood-avenue",
      "409-lynwood-avenue",
      "411-lynwood-avenue",
      "415-lynwood-avenue",
      "418-lynwood-avenue",
    ],
  },
  "solana-vista-drive": {
    id: "solana-vista-drive",
    name: "Solana Vista Drive",
    addresses: [
      "321-solana-vista-drive",
      "322-solana-vista-drive",
      "325-solana-vista-drive",
      "326-solana-vista-drive",
      "327-solana-vista-drive",
      "329-solana-vista-drive",
      "330-solana-vista-drive",
      "331-solana-vista-drive",
      "332-solana-vista-drive",
      "333-solana-vista-drive",
      "334-solana-vista-drive",
    ],
  },
  "bamboo-boulevard": {
    id: "bamboo-boulevard",
    name: "Bamboo Boulevard",
    addresses: [
      "360-bamboo-boulevard",
      "361-bamboo-boulevard",
      "362-bamboo-boulevard",
      "363-bamboo-boulevard",
      "364-bamboo-boulevard",
      "366-bamboo-boulevard",
      "367-bamboo-boulevard",
      "368-bamboo-boulevard",
      "370-bamboo-boulevard",
      "371-bamboo-boulevard",
      "374-bamboo-boulevard",
      "375-bamboo-boulevard",
      "376-bamboo-boulevard",
      "378-bamboo-boulevard",
      "380-bamboo-boulevard",
      "381-bamboo-boulevard",
      "382-bamboo-boulevard",
      "384-bamboo-boulevard",
      "385-bamboo-boulevard",
      "387-bamboo-boulevard",
      "390-bamboo-boulevard",
      "391-bamboo-boulevard",
      "393-bamboo-boulevard",
      "396-bamboo-boulevard",
      "397-bamboo-boulevard",
      "398-bamboo-boulevard",
      "399-bamboo-boulevard",
      "400-bamboo-boulevard",
      "401-bamboo-boulevard",
      "402-bamboo-boulevard",
      "403-bamboo-boulevard",
      "404-bamboo-boulevard",
      "406-bamboo-boulevard",
      "407-bamboo-boulevard",
      "409-bamboo-boulevard",
      "412-bamboo-boulevard",
      "413-bamboo-boulevard",
      "414-bamboo-boulevard",
      "419-bamboo-boulevard",
      "421-bamboo-boulevard",
      "425-bamboo-boulevard",
      "427-bamboo-boulevard",
      "428-bamboo-boulevard",
      "429-bamboo-boulevard",
      "431-bamboo-boulevard",
      "432-bamboo-boulevard",
      "434-bamboo-boulevard",
      "435-bamboo-boulevard",
      "436-bamboo-boulevard",
      "438-bamboo-boulevard",
      "440-bamboo-boulevard",
      "442-bamboo-boulevard",
      "443-bamboo-boulevard",
      "445-bamboo-boulevard",
      "448-bamboo-boulevard",
      "449-bamboo-boulevard",
      "451-bamboo-boulevard",
      "453-bamboo-boulevard",
      "456-bamboo-boulevard",
      "457-bamboo-boulevard",
      "458-bamboo-boulevard",
      "459-bamboo-boulevard",
      "461-bamboo-boulevard",
      "462-bamboo-boulevard",
      "466-bamboo-boulevard",
      "467-bamboo-boulevard",
      "468-bamboo-boulevard",
      "470-bamboo-boulevard",
      "473-bamboo-boulevard",
      "475-bamboo-boulevard",
      "478-bamboo-boulevard",
      "479-bamboo-boulevard",
      "485-bamboo-boulevard",
    ],
  },
  "lamport-lane": {
    id: "lamport-lane",
    name: "Lamport Lane",
    addresses: [
      "380-lamport-lane",
      "382-lamport-lane",
      "387-lamport-lane",
      "388-lamport-lane",
      "390-lamport-lane",
      "392-lamport-lane",
      "393-lamport-lane",
      "394-lamport-lane",
      "395-lamport-lane",
      "396-lamport-lane",
      "397-lamport-lane",
      "399-lamport-lane",
      "402-lamport-lane",
      "404-lamport-lane",
      "408-lamport-lane",
      "413-lamport-lane",
      "414-lamport-lane",
      "416-lamport-lane",
      "417-lamport-lane",
      "418-lamport-lane",
      "419-lamport-lane",
      "420-lamport-lane",
      "421-lamport-lane",
      "425-lamport-lane",
      "426-lamport-lane",
      "427-lamport-lane",
      "428-lamport-lane",
      "429-lamport-lane",
      "430-lamport-lane",
      "432-lamport-lane",
      "433-lamport-lane",
      "434-lamport-lane",
      "435-lamport-lane",
      "436-lamport-lane",
      "437-lamport-lane",
      "438-lamport-lane",
      "440-lamport-lane",
      "442-lamport-lane",
      "443-lamport-lane",
      "444-lamport-lane",
      "445-lamport-lane",
      "446-lamport-lane",
      "448-lamport-lane",
      "449-lamport-lane",
      "450-lamport-lane",
      "452-lamport-lane",
      "453-lamport-lane",
      "454-lamport-lane",
      "455-lamport-lane",
      "456-lamport-lane",
      "457-lamport-lane",
      "458-lamport-lane",
      "459-lamport-lane",
      "460-lamport-lane",
      "462-lamport-lane",
      "465-lamport-lane",
      "466-lamport-lane",
      "467-lamport-lane",
      "469-lamport-lane",
      "471-lamport-lane",
      "472-lamport-lane",
      "474-lamport-lane",
      "475-lamport-lane",
      "477-lamport-lane",
      "479-lamport-lane",
      "481-lamport-lane",
      "485-lamport-lane",
      "487-lamport-lane",
      "488-lamport-lane",
      "491-lamport-lane",
      "493-lamport-lane",
      "496-lamport-lane",
      "497-lamport-lane",
      "498-lamport-lane",
      "499-lamport-lane",
      "500-lamport-lane",
      "501-lamport-lane",
      "503-lamport-lane",
      "505-lamport-lane",
      "508-lamport-lane",
      "509-lamport-lane",
      "513-lamport-lane",
      "514-lamport-lane",
      "515-lamport-lane",
      "518-lamport-lane",
      "519-lamport-lane",
      "522-lamport-lane",
      "523-lamport-lane",
      "524-lamport-lane",
      "527-lamport-lane",
      "528-lamport-lane",
      "529-lamport-lane",
      "535-lamport-lane",
    ],
  },
  "mint-mile": {
    id: "mint-mile",
    name: "Mint Mile",
    addresses: [
      "390-mint-mile",
      "391-mint-mile",
      "393-mint-mile",
      "394-mint-mile",
      "398-mint-mile",
      "399-mint-mile",
      "400-mint-mile",
      "401-mint-mile",
      "402-mint-mile",
      "405-mint-mile",
      "407-mint-mile",
      "410-mint-mile",
      "411-mint-mile",
      "412-mint-mile",
      "416-mint-mile",
      "417-mint-mile",
      "422-mint-mile",
      "425-mint-mile",
      "426-mint-mile",
      "428-mint-mile",
      "432-mint-mile",
      "437-mint-mile",
      "439-mint-mile",
    ],
  },
  "blockchain-drive": {
    id: "blockchain-drive",
    name: "Blockchain Drive",
    addresses: [
      "402-blockchain-drive",
      "404-blockchain-drive",
      "406-blockchain-drive",
      "407-blockchain-drive",
      "412-blockchain-drive",
      "413-blockchain-drive",
      "415-blockchain-drive",
      "416-blockchain-drive",
      "417-blockchain-drive",
      "419-blockchain-drive",
      "420-blockchain-drive",
      "421-blockchain-drive",
      "429-blockchain-drive",
      "431-blockchain-drive",
    ],
  },
  "evergreen-terrace": {
    id: "evergreen-terrace",
    name: "Evergreen Terrace",
    addresses: [
      "403-evergreen-terrace",
      "407-evergreen-terrace",
      "408-evergreen-terrace",
      "411-evergreen-terrace",
      "422-evergreen-terrace",
      "428-evergreen-terrace",
      "430-evergreen-terrace",
    ],
  },
  "blaze-boulevard": {
    id: "blaze-boulevard",
    name: "Blaze Boulevard",
    addresses: [
      "405-blaze-boulevard",
      "406-blaze-boulevard",
      "407-blaze-boulevard",
      "409-blaze-boulevard",
      "410-blaze-boulevard",
      "411-blaze-boulevard",
      "412-blaze-boulevard",
      "413-blaze-boulevard",
      "414-blaze-boulevard",
      "415-blaze-boulevard",
      "416-blaze-boulevard",
      "417-blaze-boulevard",
      "418-blaze-boulevard",
      "419-blaze-boulevard",
      "420-blaze-boulevard",
      "421-blaze-boulevard",
      "422-blaze-boulevard",
      "423-blaze-boulevard",
      "424-blaze-boulevard",
      "425-blaze-boulevard",
      "426-blaze-boulevard",
      "428-blaze-boulevard",
      "429-blaze-boulevard",
      "430-blaze-boulevard",
      "431-blaze-boulevard",
      "432-blaze-boulevard",
      "433-blaze-boulevard",
      "435-blaze-boulevard",
      "436-blaze-boulevard",
      "437-blaze-boulevard",
      "439-blaze-boulevard",
    ],
  },
  "grand-avenue": {
    id: "grand-avenue",
    name: "Grand Avenue",
    addresses: [
      "407-grand-avenue",
      "408-grand-avenue",
      "409-grand-avenue",
      "413-grand-avenue",
      "415-grand-avenue",
      "417-grand-avenue",
      "418-grand-avenue",
      "421-grand-avenue",
      "423-grand-avenue",
      "424-grand-avenue",
      "425-grand-avenue",
      "426-grand-avenue",
      "427-grand-avenue",
      "428-grand-avenue",
      "430-grand-avenue",
      "431-grand-avenue",
      "433-grand-avenue",
      "434-grand-avenue",
      "436-grand-avenue",
      "438-grand-avenue",
      "440-grand-avenue",
      "441-grand-avenue",
      "442-grand-avenue",
      "443-grand-avenue",
      "444-grand-avenue",
      "445-grand-avenue",
      "446-grand-avenue",
      "447-grand-avenue",
      "448-grand-avenue",
      "450-grand-avenue",
    ],
  },
  "block-hills-drive": {
    id: "block-hills-drive",
    name: "Block Hills Drive",
    addresses: [
      "409-block-hills-drive",
      "410-block-hills-drive",
      "411-block-hills-drive",
      "412-block-hills-drive",
      "413-block-hills-drive",
      "414-block-hills-drive",
      "415-block-hills-drive",
      "417-block-hills-drive",
      "419-block-hills-drive",
      "422-block-hills-drive",
      "423-block-hills-drive",
      "424-block-hills-drive",
      "425-block-hills-drive",
      "426-block-hills-drive",
      "427-block-hills-drive",
      "428-block-hills-drive",
      "429-block-hills-drive",
      "430-block-hills-drive",
      "431-block-hills-drive",
      "434-block-hills-drive",
      "435-block-hills-drive",
      "437-block-hills-drive",
      "438-block-hills-drive",
      "439-block-hills-drive",
      "440-block-hills-drive",
      "443-block-hills-drive",
      "444-block-hills-drive",
      "445-block-hills-drive",
      "446-block-hills-drive",
      "447-block-hills-drive",
      "448-block-hills-drive",
      "449-block-hills-drive",
    ],
  },
  "dahlia-drive": {
    id: "dahlia-drive",
    name: "Dahlia Drive",
    addresses: [
      "410-dahlia-drive",
      "411-dahlia-drive",
      "412-dahlia-drive",
      "416-dahlia-drive",
      "417-dahlia-drive",
      "420-dahlia-drive",
      "423-dahlia-drive",
      "425-dahlia-drive",
      "431-dahlia-drive",
      "434-dahlia-drive",
      "437-dahlia-drive",
      "442-dahlia-drive",
      "443-dahlia-drive",
      "444-dahlia-drive",
      "445-dahlia-drive",
      "447-dahlia-drive",
      "451-dahlia-drive",
      "452-dahlia-drive",
      "461-dahlia-drive",
      "462-dahlia-drive",
      "463-dahlia-drive",
      "466-dahlia-drive",
      "471-dahlia-drive",
      "472-dahlia-drive",
      "479-dahlia-drive",
      "481-dahlia-drive",
      "482-dahlia-drive",
      "485-dahlia-drive",
      "487-dahlia-drive",
      "490-dahlia-drive",
      "492-dahlia-drive",
      "495-dahlia-drive",
      "496-dahlia-drive",
      "498-dahlia-drive",
      "503-dahlia-drive",
      "504-dahlia-drive",
      "505-dahlia-drive",
      "508-dahlia-drive",
      "514-dahlia-drive",
      "516-dahlia-drive",
      "522-dahlia-drive",
      "523-dahlia-drive",
      "526-dahlia-drive",
      "530-dahlia-drive",
      "531-dahlia-drive",
      "532-dahlia-drive",
      "533-dahlia-drive",
      "534-dahlia-drive",
      "539-dahlia-drive",
      "540-dahlia-drive",
      "541-dahlia-drive",
      "544-dahlia-drive",
      "546-dahlia-drive",
      "549-dahlia-drive",
      "550-dahlia-drive",
      "558-dahlia-drive",
      "559-dahlia-drive",
      "560-dahlia-drive",
      "563-dahlia-drive",
      "569-dahlia-drive",
      "572-dahlia-drive",
      "573-dahlia-drive",
      "576-dahlia-drive",
      "578-dahlia-drive",
      "580-dahlia-drive",
      "583-dahlia-drive",
      "587-dahlia-drive",
      "596-dahlia-drive",
      "597-dahlia-drive",
      "609-dahlia-drive",
      "615-dahlia-drive",
      "616-dahlia-drive",
      "618-dahlia-drive",
      "620-dahlia-drive",
      "626-dahlia-drive",
      "629-dahlia-drive",
      "632-dahlia-drive",
      "633-dahlia-drive",
      "634-dahlia-drive",
      "636-dahlia-drive",
      "637-dahlia-drive",
      "639-dahlia-drive",
      "641-dahlia-drive",
      "650-dahlia-drive",
      "654-dahlia-drive",
      "656-dahlia-drive",
      "658-dahlia-drive",
      "659-dahlia-drive",
      "660-dahlia-drive",
      "661-dahlia-drive",
      "662-dahlia-drive",
      "663-dahlia-drive",
      "665-dahlia-drive",
      "666-dahlia-drive",
      "668-dahlia-drive",
      "669-dahlia-drive",
    ],
  },
  "ligma-lane": {
    id: "ligma-lane",
    name: "Ligma Lane",
    addresses: [
      "60-ligma-lane",
      "66-ligma-lane",
      "69-ligma-lane",
      "76-ligma-lane",
      "420-ligma-lane",
    ],
  },
  "groove-path": {
    id: "groove-path",
    name: "Groove Path",
    addresses: ["45-groove-path"],
  },
  "king-street": {
    id: "king-street",
    name: "King Street",
    addresses: [
      "52-king-street",
      "53-king-street",
      "54-king-street",
      "55-king-street",
      "56-king-street",
      "57-king-street",
      "58-king-street",
      "59-king-street",
      "60-king-street",
      "61-king-street",
      "63-king-street",
      "64-king-street",
      "65-king-street",
      "66-king-street",
      "67-king-street",
      "68-king-street",
      "70-king-street",
      "71-king-street",
      "72-king-street",
      "73-king-street",
      "74-king-street",
      "75-king-street",
      "76-king-street",
      "77-king-street",
      "78-king-street",
      "79-king-street",
      "80-king-street",
      "81-king-street",
      "82-king-street",
      "83-king-street",
      "84-king-street",
      "85-king-street",
      "87-king-street",
      "88-king-street",
      "89-king-street",
    ],
  },
  "abbey-road": {
    id: "abbey-road",
    name: "Abbey Road",
    addresses: [
      "53-abbey-road",
      "54-abbey-road",
      "55-abbey-road",
      "56-abbey-road",
      "57-abbey-road",
      "58-abbey-road",
      "60-abbey-road",
      "62-abbey-road",
      "63-abbey-road",
      "64-abbey-road",
      "65-abbey-road",
      "66-abbey-road",
      "67-abbey-road",
      "69-abbey-road",
      "70-abbey-road",
      "71-abbey-road",
      "72-abbey-road",
      "73-abbey-road",
      "74-abbey-road",
      "75-abbey-road",
      "76-abbey-road",
      "77-abbey-road",
      "78-abbey-road",
      "79-abbey-road",
      "80-abbey-road",
      "82-abbey-road",
      "83-abbey-road",
      "86-abbey-road",
      "87-abbey-road",
      "88-abbey-road",
      "89-abbey-road",
      "90-abbey-road",
      "91-abbey-road",
    ],
  },
  "jane-jacobs-way": {
    id: "jane-jacobs-way",
    name: "Jane Jacobs Way",
    addresses: [
      "532-jane-jacobs-way",
      "533-jane-jacobs-way",
      "534-jane-jacobs-way",
      "535-jane-jacobs-way",
      "536-jane-jacobs-way",
      "537-jane-jacobs-way",
      "538-jane-jacobs-way",
      "540-jane-jacobs-way",
      "542-jane-jacobs-way",
      "543-jane-jacobs-way",
      "544-jane-jacobs-way",
      "545-jane-jacobs-way",
      "546-jane-jacobs-way",
      "547-jane-jacobs-way",
      "548-jane-jacobs-way",
      "550-jane-jacobs-way",
      "551-jane-jacobs-way",
      "553-jane-jacobs-way",
      "554-jane-jacobs-way",
      "555-jane-jacobs-way",
      "556-jane-jacobs-way",
      "558-jane-jacobs-way",
      "559-jane-jacobs-way",
      "560-jane-jacobs-way",
      "561-jane-jacobs-way",
      "562-jane-jacobs-way",
      "563-jane-jacobs-way",
      "565-jane-jacobs-way",
      "566-jane-jacobs-way",
      "567-jane-jacobs-way",
      "568-jane-jacobs-way",
    ],
  },
  "poplar-street": {
    id: "poplar-street",
    name: "Poplar Street",
    addresses: [
      "541-poplar-street",
      "544-poplar-street",
      "545-poplar-street",
      "546-poplar-street",
      "547-poplar-street",
      "550-poplar-street",
      "552-poplar-street",
      "554-poplar-street",
      "556-poplar-street",
      "557-poplar-street",
      "559-poplar-street",
      "561-poplar-street",
      "562-poplar-street",
      "564-poplar-street",
      "565-poplar-street",
      "566-poplar-street",
      "568-poplar-street",
      "569-poplar-street",
      "570-poplar-street",
      "572-poplar-street",
      "573-poplar-street",
      "575-poplar-street",
      "578-poplar-street",
      "580-poplar-street",
      "585-poplar-street",
      "592-poplar-street",
      "593-poplar-street",
      "595-poplar-street",
      "596-poplar-street",
      "599-poplar-street",
      "600-poplar-street",
      "601-poplar-street",
      "603-poplar-street",
      "604-poplar-street",
      "605-poplar-street",
      "608-poplar-street",
      "609-poplar-street",
      "613-poplar-street",
      "615-poplar-street",
      "616-poplar-street",
      "617-poplar-street",
      "618-poplar-street",
      "620-poplar-street",
      "624-poplar-street",
      "626-poplar-street",
      "628-poplar-street",
      "629-poplar-street",
      "630-poplar-street",
      "631-poplar-street",
      "632-poplar-street",
      "639-poplar-street",
      "640-poplar-street",
      "641-poplar-street",
      "643-poplar-street",
      "645-poplar-street",
      "646-poplar-street",
      "647-poplar-street",
      "651-poplar-street",
      "652-poplar-street",
      "657-poplar-street",
      "659-poplar-street",
      "661-poplar-street",
      "663-poplar-street",
      "666-poplar-street",
      "667-poplar-street",
      "668-poplar-street",
      "670-poplar-street",
      "671-poplar-street",
      "675-poplar-street",
      "680-poplar-street",
      "681-poplar-street",
      "682-poplar-street",
      "686-poplar-street",
      "688-poplar-street",
      "689-poplar-street",
    ],
  },
  "dip-drive": {
    id: "dip-drive",
    name: "Dip Drive",
    addresses: [
      "543-dip-drive",
      "544-dip-drive",
      "549-dip-drive",
      "550-dip-drive",
      "551-dip-drive",
      "553-dip-drive",
      "554-dip-drive",
      "555-dip-drive",
      "556-dip-drive",
      "557-dip-drive",
      "560-dip-drive",
      "561-dip-drive",
      "562-dip-drive",
      "563-dip-drive",
      "564-dip-drive",
      "566-dip-drive",
      "568-dip-drive",
      "569-dip-drive",
      "571-dip-drive",
      "572-dip-drive",
      "573-dip-drive",
      "574-dip-drive",
      "578-dip-drive",
      "579-dip-drive",
      "580-dip-drive",
      "581-dip-drive",
      "582-dip-drive",
      "588-dip-drive",
      "589-dip-drive",
      "591-dip-drive",
      "592-dip-drive",
      "594-dip-drive",
      "595-dip-drive",
      "596-dip-drive",
      "599-dip-drive",
      "600-dip-drive",
      "601-dip-drive",
      "602-dip-drive",
      "603-dip-drive",
      "604-dip-drive",
      "605-dip-drive",
      "606-dip-drive",
      "607-dip-drive",
      "612-dip-drive",
      "614-dip-drive",
      "617-dip-drive",
      "618-dip-drive",
      "619-dip-drive",
      "620-dip-drive",
      "622-dip-drive",
      "623-dip-drive",
      "624-dip-drive",
      "625-dip-drive",
      "626-dip-drive",
      "627-dip-drive",
      "629-dip-drive",
      "632-dip-drive",
      "633-dip-drive",
      "636-dip-drive",
      "637-dip-drive",
      "638-dip-drive",
      "639-dip-drive",
      "641-dip-drive",
      "642-dip-drive",
      "643-dip-drive",
      "644-dip-drive",
      "646-dip-drive",
      "647-dip-drive",
      "648-dip-drive",
      "649-dip-drive",
      "652-dip-drive",
    ],
  },
  "cluster-lane": {
    id: "cluster-lane",
    name: "Cluster Lane",
    addresses: [
      "580-cluster-lane",
      "582-cluster-lane",
      "584-cluster-lane",
      "585-cluster-lane",
      "587-cluster-lane",
      "590-cluster-lane",
      "592-cluster-lane",
      "593-cluster-lane",
      "594-cluster-lane",
      "595-cluster-lane",
      "597-cluster-lane",
      "598-cluster-lane",
      "599-cluster-lane",
      "601-cluster-lane",
      "602-cluster-lane",
      "603-cluster-lane",
      "604-cluster-lane",
      "607-cluster-lane",
      "608-cluster-lane",
      "609-cluster-lane",
      "610-cluster-lane",
      "613-cluster-lane",
      "614-cluster-lane",
      "616-cluster-lane",
      "617-cluster-lane",
      "618-cluster-lane",
      "619-cluster-lane",
      "621-cluster-lane",
      "622-cluster-lane",
      "624-cluster-lane",
      "625-cluster-lane",
      "627-cluster-lane",
      "629-cluster-lane",
    ],
  },
  "helix-avenue": {
    id: "helix-avenue",
    name: "Helix Avenue",
    addresses: [
      "580-helix-avenue",
      "582-helix-avenue",
      "585-helix-avenue",
      "586-helix-avenue",
      "587-helix-avenue",
      "588-helix-avenue",
      "589-helix-avenue",
      "591-helix-avenue",
      "592-helix-avenue",
      "593-helix-avenue",
      "594-helix-avenue",
      "596-helix-avenue",
      "597-helix-avenue",
      "598-helix-avenue",
      "599-helix-avenue",
      "601-helix-avenue",
      "602-helix-avenue",
      "603-helix-avenue",
      "604-helix-avenue",
      "605-helix-avenue",
      "608-helix-avenue",
      "609-helix-avenue",
      "610-helix-avenue",
      "613-helix-avenue",
      "615-helix-avenue",
      "616-helix-avenue",
      "618-helix-avenue",
      "619-helix-avenue",
      "620-helix-avenue",
      "621-helix-avenue",
      "623-helix-avenue",
      "627-helix-avenue",
      "628-helix-avenue",
      "630-helix-avenue",
      "632-helix-avenue",
      "634-helix-avenue",
      "635-helix-avenue",
      "636-helix-avenue",
      "637-helix-avenue",
      "638-helix-avenue",
      "639-helix-avenue",
      "640-helix-avenue",
      "641-helix-avenue",
      "642-helix-avenue",
      "644-helix-avenue",
      "646-helix-avenue",
      "647-helix-avenue",
      "648-helix-avenue",
      "649-helix-avenue",
      "650-helix-avenue",
      "651-helix-avenue",
      "652-helix-avenue",
      "654-helix-avenue",
      "657-helix-avenue",
      "659-helix-avenue",
      "661-helix-avenue",
      "663-helix-avenue",
      "664-helix-avenue",
      "666-helix-avenue",
      "669-helix-avenue",
      "670-helix-avenue",
      "672-helix-avenue",
      "674-helix-avenue",
      "675-helix-avenue",
      "677-helix-avenue",
      "679-helix-avenue",
      "680-helix-avenue",
      "681-helix-avenue",
      "685-helix-avenue",
      "691-helix-avenue",
      "692-helix-avenue",
      "693-helix-avenue",
      "694-helix-avenue",
      "695-helix-avenue",
      "697-helix-avenue",
      "699-helix-avenue",
      "700-helix-avenue",
      "701-helix-avenue",
      "703-helix-avenue",
      "704-helix-avenue",
      "705-helix-avenue",
      "706-helix-avenue",
      "709-helix-avenue",
      "710-helix-avenue",
      "711-helix-avenue",
      "712-helix-avenue",
      "713-helix-avenue",
      "714-helix-avenue",
      "715-helix-avenue",
      "716-helix-avenue",
      "717-helix-avenue",
      "718-helix-avenue",
      "719-helix-avenue",
      "721-helix-avenue",
      "722-helix-avenue",
      "726-helix-avenue",
      "727-helix-avenue",
      "728-helix-avenue",
      "729-helix-avenue",
    ],
  },
  "rip-street": {
    id: "rip-street",
    name: "Rip Street",
    addresses: [
      "605-rip-street",
      "607-rip-street",
      "608-rip-street",
      "610-rip-street",
      "612-rip-street",
      "613-rip-street",
      "614-rip-street",
      "617-rip-street",
      "618-rip-street",
      "620-rip-street",
      "621-rip-street",
      "622-rip-street",
      "625-rip-street",
      "627-rip-street",
      "629-rip-street",
      "630-rip-street",
      "631-rip-street",
      "632-rip-street",
      "634-rip-street",
      "635-rip-street",
      "637-rip-street",
      "638-rip-street",
      "640-rip-street",
      "641-rip-street",
      "644-rip-street",
      "649-rip-street",
      "653-rip-street",
      "654-rip-street",
      "656-rip-street",
      "658-rip-street",
      "659-rip-street",
      "660-rip-street",
      "661-rip-street",
      "662-rip-street",
      "663-rip-street",
      "667-rip-street",
      "668-rip-street",
      "669-rip-street",
      "670-rip-street",
      "671-rip-street",
      "672-rip-street",
      "674-rip-street",
      "678-rip-street",
      "680-rip-street",
      "681-rip-street",
      "683-rip-street",
      "685-rip-street",
      "686-rip-street",
      "687-rip-street",
      "688-rip-street",
      "691-rip-street",
      "694-rip-street",
      "695-rip-street",
      "697-rip-street",
      "698-rip-street",
      "699-rip-street",
      "700-rip-street",
      "701-rip-street",
      "702-rip-street",
      "703-rip-street",
      "707-rip-street",
      "708-rip-street",
      "709-rip-street",
      "710-rip-street",
    ],
  },
  "clipping-drive": {
    id: "clipping-drive",
    name: "Clipping Drive",
    addresses: [
      "630-clipping-drive",
      "632-clipping-drive",
      "634-clipping-drive",
      "636-clipping-drive",
      "637-clipping-drive",
      "638-clipping-drive",
      "640-clipping-drive",
      "641-clipping-drive",
      "643-clipping-drive",
      "646-clipping-drive",
      "647-clipping-drive",
      "649-clipping-drive",
      "650-clipping-drive",
      "651-clipping-drive",
      "652-clipping-drive",
      "653-clipping-drive",
      "654-clipping-drive",
      "655-clipping-drive",
      "656-clipping-drive",
      "657-clipping-drive",
      "659-clipping-drive",
      "660-clipping-drive",
      "662-clipping-drive",
      "663-clipping-drive",
      "664-clipping-drive",
      "667-clipping-drive",
      "668-clipping-drive",
      "669-clipping-drive",
      "673-clipping-drive",
      "675-clipping-drive",
      "676-clipping-drive",
      "677-clipping-drive",
      "678-clipping-drive",
      "680-clipping-drive",
      "681-clipping-drive",
      "682-clipping-drive",
      "683-clipping-drive",
      "685-clipping-drive",
      "687-clipping-drive",
      "689-clipping-drive",
      "690-clipping-drive",
      "691-clipping-drive",
      "693-clipping-drive",
      "694-clipping-drive",
      "696-clipping-drive",
      "700-clipping-drive",
      "701-clipping-drive",
      "703-clipping-drive",
      "704-clipping-drive",
      "707-clipping-drive",
      "708-clipping-drive",
      "709-clipping-drive",
      "710-clipping-drive",
      "711-clipping-drive",
      "712-clipping-drive",
      "714-clipping-drive",
      "715-clipping-drive",
      "717-clipping-drive",
      "720-clipping-drive",
      "724-clipping-drive",
      "725-clipping-drive",
      "726-clipping-drive",
      "727-clipping-drive",
      "729-clipping-drive",
      "730-clipping-drive",
      "731-clipping-drive",
      "732-clipping-drive",
      "734-clipping-drive",
      "735-clipping-drive",
      "736-clipping-drive",
      "737-clipping-drive",
    ],
  },
  "epoch-avenue": {
    id: "epoch-avenue",
    name: "Epoch Avenue",
    addresses: [
      "631-epoch-avenue",
      "632-epoch-avenue",
      "633-epoch-avenue",
      "634-epoch-avenue",
      "636-epoch-avenue",
      "637-epoch-avenue",
      "638-epoch-avenue",
      "639-epoch-avenue",
      "640-epoch-avenue",
      "641-epoch-avenue",
      "643-epoch-avenue",
      "645-epoch-avenue",
      "646-epoch-avenue",
      "648-epoch-avenue",
      "651-epoch-avenue",
      "652-epoch-avenue",
      "653-epoch-avenue",
      "655-epoch-avenue",
      "656-epoch-avenue",
      "657-epoch-avenue",
      "658-epoch-avenue",
      "664-epoch-avenue",
      "667-epoch-avenue",
      "668-epoch-avenue",
      "670-epoch-avenue",
      "671-epoch-avenue",
      "673-epoch-avenue",
      "674-epoch-avenue",
      "675-epoch-avenue",
      "676-epoch-avenue",
      "678-epoch-avenue",
      "679-epoch-avenue",
      "680-epoch-avenue",
      "682-epoch-avenue",
      "684-epoch-avenue",
      "685-epoch-avenue",
      "687-epoch-avenue",
      "688-epoch-avenue",
      "690-epoch-avenue",
      "691-epoch-avenue",
      "692-epoch-avenue",
      "693-epoch-avenue",
      "695-epoch-avenue",
      "696-epoch-avenue",
      "697-epoch-avenue",
      "698-epoch-avenue",
      "699-epoch-avenue",
      "700-epoch-avenue",
      "701-epoch-avenue",
      "702-epoch-avenue",
      "704-epoch-avenue",
      "706-epoch-avenue",
      "707-epoch-avenue",
      "708-epoch-avenue",
      "709-epoch-avenue",
    ],
  },
  "ramsey-street": {
    id: "ramsey-street",
    name: "Ramsey Street",
    addresses: [
      "650-ramsey-street",
      "652-ramsey-street",
      "653-ramsey-street",
      "655-ramsey-street",
      "656-ramsey-street",
      "658-ramsey-street",
      "659-ramsey-street",
      "660-ramsey-street",
      "664-ramsey-street",
      "665-ramsey-street",
      "666-ramsey-street",
      "667-ramsey-street",
      "669-ramsey-street",
      "670-ramsey-street",
      "671-ramsey-street",
      "675-ramsey-street",
      "677-ramsey-street",
      "678-ramsey-street",
      "679-ramsey-street",
      "680-ramsey-street",
      "681-ramsey-street",
      "683-ramsey-street",
      "684-ramsey-street",
      "686-ramsey-street",
      "687-ramsey-street",
      "688-ramsey-street",
      "689-ramsey-street",
      "695-ramsey-street",
    ],
  },
  "rodeo-drive": {
    id: "rodeo-drive",
    name: "Rodeo Drive",
    addresses: [
      "732-rodeo-drive",
      "736-rodeo-drive",
      "737-rodeo-drive",
      "738-rodeo-drive",
      "741-rodeo-drive",
      "743-rodeo-drive",
      "744-rodeo-drive",
      "747-rodeo-drive",
      "748-rodeo-drive",
      "750-rodeo-drive",
      "753-rodeo-drive",
      "754-rodeo-drive",
      "755-rodeo-drive",
      "756-rodeo-drive",
      "758-rodeo-drive",
      "759-rodeo-drive",
      "760-rodeo-drive",
      "764-rodeo-drive",
      "765-rodeo-drive",
      "767-rodeo-drive",
      "768-rodeo-drive",
      "771-rodeo-drive",
      "774-rodeo-drive",
      "775-rodeo-drive",
      "777-rodeo-drive",
      "779-rodeo-drive",
      "781-rodeo-drive",
      "784-rodeo-drive",
      "786-rodeo-drive",
      "787-rodeo-drive",
      "790-rodeo-drive",
      "793-rodeo-drive",
      "795-rodeo-drive",
      "796-rodeo-drive",
      "797-rodeo-drive",
      "800-rodeo-drive",
      "801-rodeo-drive",
      "805-rodeo-drive",
      "807-rodeo-drive",
      "808-rodeo-drive",
      "809-rodeo-drive",
      "810-rodeo-drive",
      "811-rodeo-drive",
      "812-rodeo-drive",
      "813-rodeo-drive",
      "814-rodeo-drive",
      "819-rodeo-drive",
    ],
  },
  "battle-street": {
    id: "battle-street",
    name: "Battle Street",
    addresses: [
      "789-battle-street",
      "790-battle-street",
      "791-battle-street",
      "793-battle-street",
      "795-battle-street",
      "796-battle-street",
      "797-battle-street",
      "798-battle-street",
      "799-battle-street",
      "802-battle-street",
      "803-battle-street",
      "804-battle-street",
      "805-battle-street",
      "806-battle-street",
      "808-battle-street",
      "809-battle-street",
      "810-battle-street",
      "811-battle-street",
      "813-battle-street",
      "817-battle-street",
      "818-battle-street",
      "819-battle-street",
      "820-battle-street",
      "821-battle-street",
      "822-battle-street",
      "823-battle-street",
      "824-battle-street",
      "825-battle-street",
      "826-battle-street",
      "827-battle-street",
      "828-battle-street",
      "829-battle-street",
      "830-battle-street",
      "832-battle-street",
      "833-battle-street",
      "838-battle-street",
      "839-battle-street",
      "840-battle-street",
      "841-battle-street",
      "842-battle-street",
      "844-battle-street",
      "845-battle-street",
      "846-battle-street",
      "847-battle-street",
      "849-battle-street",
      "850-battle-street",
      "851-battle-street",
      "852-battle-street",
      "853-battle-street",
      "854-battle-street",
      "857-battle-street",
      "858-battle-street",
      "860-battle-street",
      "861-battle-street",
      "862-battle-street",
      "864-battle-street",
      "865-battle-street",
      "867-battle-street",
      "868-battle-street",
      "869-battle-street",
      "870-battle-street",
      "872-battle-street",
      "873-battle-street",
      "874-battle-street",
      "875-battle-street",
      "876-battle-street",
      "877-battle-street",
      "879-battle-street",
      "881-battle-street",
      "883-battle-street",
      "884-battle-street",
      "885-battle-street",
      "886-battle-street",
      "887-battle-street",
      "888-battle-street",
      "889-battle-street",
      "890-battle-street",
      "891-battle-street",
      "892-battle-street",
      "893-battle-street",
      "894-battle-street",
      "895-battle-street",
      "896-battle-street",
      "897-battle-street",
      "898-battle-street",
      "900-battle-street",
      "901-battle-street",
      "902-battle-street",
      "904-battle-street",
      "905-battle-street",
      "906-battle-street",
      "907-battle-street",
      "908-battle-street",
      "910-battle-street",
      "911-battle-street",
      "912-battle-street",
      "913-battle-street",
      "915-battle-street",
      "916-battle-street",
      "917-battle-street",
      "918-battle-street",
      "920-battle-street",
      "923-battle-street",
      "925-battle-street",
      "926-battle-street",
      "927-battle-street",
      "929-battle-street",
      "933-battle-street",
    ],
  },
  broadway: {
    id: "broadway",
    name: "Broadway",
    addresses: [
      "807-broadway",
      "809-broadway",
      "810-broadway",
      "812-broadway",
      "814-broadway",
      "816-broadway",
      "817-broadway",
      "820-broadway",
      "822-broadway",
      "826-broadway",
      "827-broadway",
      "828-broadway",
      "829-broadway",
      "830-broadway",
      "831-broadway",
      "832-broadway",
      "834-broadway",
      "835-broadway",
      "836-broadway",
      "840-broadway",
      "843-broadway",
      "844-broadway",
      "845-broadway",
      "846-broadway",
      "847-broadway",
      "850-broadway",
      "851-broadway",
      "852-broadway",
      "854-broadway",
      "856-broadway",
      "858-broadway",
      "860-broadway",
      "863-broadway",
      "866-broadway",
      "867-broadway",
      "868-broadway",
      "870-broadway",
      "872-broadway",
      "873-broadway",
      "876-broadway",
      "877-broadway",
      "878-broadway",
      "881-broadway",
      "882-broadway",
      "886-broadway",
      "887-broadway",
      "888-broadway",
      "889-broadway",
    ],
  },
  "vibe-drive": {
    id: "vibe-drive",
    name: "Vibe Drive",
    addresses: ["91-vibe-drive"],
  },
  "dojo-drive": {
    id: "dojo-drive",
    name: "Dojo Drive",
    addresses: ["72-dojo-drive"],
  },
};
