import React, { useEffect } from "react";
import styled from "styled-components";

const Base = styled.div`
  position: relative;
`;

type BackgroundProps = {
  color: string;
  image?: string;
};

const BannerImage = styled.div`
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vw * (23 / 172) + 220px);
  left: 0px;
  position: absolute;
  top: 135px;
  width: 100%;
  z-index: -1000;
`;

export const Background = ({
  color,
  image,
  children,
}: React.PropsWithChildren<BackgroundProps>) => {
  useEffect(() => {
    document.body.style.backgroundColor = color;
  });
  return (
    <Base>
      {image && <BannerImage style={{ backgroundImage: `url(${image})` }} />}
      {children}
    </Base>
  );
};
